export default function HeroSection() {
  return (
    <section id="heroSection" className="hero--section">
      <div className="hero--section--content--box">
        <div className="hero--section--content">
        
          <h1 className="hero--section--title">
            <span className="hero--section-title--color">Platesys</span>{" "}
          </h1>
          <p className="hero--section-description">
          Nosso foco é oferecer produtos e serviços que atendam às
          <br />necessidades de nossos clientes, sempre com qualidade e 
          <br />eficiência. Somos uma empresa de software que tem como 
          <br />objetivo oferecer soluções tecnológicas inovadoras para nossos clientes. 
          <br />Nossa equipe é composta por profissionais altamente qualificados e apaixonados pelo que fazem.
          </p>
        </div>
     
      </div>
      <div className="hero--section--img">
        <img src="./img/hero_img.png" alt="Hero Section" />
      </div>
    </section>
  );
}
